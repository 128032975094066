import { reactive } from 'vue';
import { defineStore } from 'pinia';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { APP_NETWORK_NAME } from '@/helpers/networkParams.helper';
import { useLocalStorage } from '@/composables/useLocalStorage';
import { SUPPORTED_NETWORK_MODE } from '@/constants/SUPPORTED_NETWORK_MODE';
import { ConnectorState } from './models/connector.state';
import { getUrlWithoutParam, updatePageUrl } from '@/helpers/appParams.helper';
import { reloadPage } from '@/utils/page';
import { CustomLockInstance } from './models/lock-instance.interface';

const CONNECTOR_LOG = 'CONNECTOR';

export const useConnector = defineStore('Connector', () => {
  let auth: CustomLockInstance;

  const connectorState = reactive<ConnectorState>({
    connector: false,
    walletConnector: null,
  });

  async function initConnector(connector?: string) {
    useLocalStorage().setItem(`network_${SUPPORTED_NETWORK_MODE}`, APP_NETWORK_NAME);
    auth = getInstance();
    if (!auth.login) {
      throw Error(`[${CONNECTOR_LOG}:INIT] Can not create Lock instance.`);
    }
    connectorState.connector = connector ?? (await auth.getConnector());
    console.log(`[${CONNECTOR_LOG}:INIT] connector : `, connectorState.connector);
    return connectorState.connector;
  }

  async function loadProvider() {
    try {
      if (auth.provider.value.removeAllListeners && !auth.provider.value.isTorus) {
        auth.provider.value.removeAllListeners();
      }

      console.log(`[${CONNECTOR_LOG}:LOAD_PROVIDER] provider : `, auth.provider);

      const handleChainChanged = () => {
        updatePageUrl(getUrlWithoutParam('network'));
      };

      // HACK:FIX: fix for Flint Wallet
      if (auth.provider.value.isFlint) {
        console.warn(`[${CONNECTOR_LOG}:FIX:FLINT:WALLET] apply fix _events`);
        const _events = auth.provider.value._events;
        _events['chainChanged'] = handleChainChanged;
        _events['accountsChanged'] = reloadPage;
      }

      auth.provider.value.on?.('chainChanged', handleChainChanged);
      auth.provider.value.on?.('accountsChanged', reloadPage);
    } catch (err) {
      console.warn(`[${CONNECTOR_LOG}:LOAD_PROVIDER] Error : `, err);
      return Promise.reject(err);
    }
  }

  return {
    connectorState,
    initConnector,
    loadProvider,
  };
});
