import { computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { JsonRpcProvider, Provider, Web3Provider } from '@ethersproject/providers';
import { CONNECTORS } from '@/helpers/connectors/wallets/connectors.helper';
import { EVMWalletState } from './models/evm-wallet.state';
import {
  HardwareWalletIsNotSupportedError,
  WalletError,
  WalletIsNotInstalledError,
} from '@/sdk/errors';
import { NETWORK_ENGINE, getNetworkParams } from '@/helpers/networkParams.helper';
import { isNetworkSupported, SUPPORTED_NETWORK_IDS } from '@/utils/blockchain';
import { useConnector } from './connector/useConnector';
import { CustomLockInstance } from './connector/models/lock-instance.interface';
import networks from '@/helpers/networks';

const EVM_WALLET_LOG = 'EVM:WALLET';

export const useEVMWallet = defineStore('EVMWallet', () => {
  const { connectorState, initConnector, loadProvider } = useConnector();

  let web3Engine: Provider | null = null;

  const walletState = reactive<EVMWalletState>({
    authLoading: false,
    isInjected: false,
    network: null,
    account: '0x0000000000000000000000000000000000000000',
    connector: null,
  });

  const getWeb3Engine = () => web3Engine;

  // GETTERS
  const getWalletName = computed(() => {
    return walletState.account || null;
  });

  const getWalletNetwork = computed(() => {
    return walletState.network ? networks[walletState.network.chainId] : null;
  });

  const getSupportedNetworksNames = computed(() => {
    const supportedNetworks = SUPPORTED_NETWORK_IDS!.map(networkId => {
      return networks[networkId]?.name || 'not defined';
    });
    return supportedNetworks.join(', ');
  });

  const getFirstSupportedNetwork = computed(() => {
    return getNetworkParams(SUPPORTED_NETWORK_IDS![0]);
  });

  function resetConnectionState() {
    walletState.isInjected = false;
    walletState.network = null;
    walletState.account = null;
    walletState.isNetworkSupported = null;
  }

  // ACTIONS
  async function initWeb3Engine() {
    web3Engine = await new JsonRpcProvider(NETWORK_ENGINE);
  }

  async function login(connector = 'injected') {
    if (!connectorState.connector) {
      await initConnector(connector);
    }
    const auth: CustomLockInstance = getInstance();
    walletState.authLoading = true;

    try {
      await auth.login(connector);

      if (auth.provider.value) {
        auth.web3 = new Web3Provider(auth.provider.value);
        connectorState.walletConnector = CONNECTORS[connector];
        walletState.connector = CONNECTORS[connector];

        await loadProvider();

        const [network, accounts] = await Promise.all([
          auth.web3.getNetwork(),
          auth.web3.listAccounts(),
        ]);

        walletState.isInjected = true;
        web3Engine = auth.web3;
        walletState.network = network;
        walletState.account = accounts.length > 0 ? accounts[0] : null;
        walletState.isNetworkSupported = isNetworkSupported(network?.chainId);
      } else if (connector !== 'walletconnect') {
        // NOTE: Wallet is not installed
        throw new WalletIsNotInstalledError();
      } else {
        // NOTE: Wallet connection error
        throw new WalletError();
      }
    } catch (error) {
      if (error instanceof HardwareWalletIsNotSupportedError) {
        disconnect();
      }
      throw error;
    } finally {
      walletState.authLoading = false;
    }

    console.log(`[${EVM_WALLET_LOG}:LOGIN] State : `, walletState);
  }

  async function connect() {
    await initConnector();
    if (connectorState.connector) {
      await login(connectorState.connector);
    }
  }

  async function disconnect() {
    const auth: CustomLockInstance = getInstance();
    try {
      await auth.logout();
      resetConnectionState();
    } catch (e) {
      console.error('[EVM:WALLET] ERROR : ', e);
    }
  }

  return {
    walletState,
    getWeb3Engine,
    // actions
    initWeb3Engine,
    connect,
    disconnect,
    login,
    // getters
    getWalletName,
    getWalletNetwork,
    getSupportedNetworksNames,
    getFirstSupportedNetwork,
  };
});
