export const HEADER_HEIGHT = 56;
export const APP_THEME = {
  DARK: 'dark',
  LIGHT: 'light',
};
export const BREAKPOINTS = {
  SM: 576,
  XL: 1200,
  XXL: 1920,
};
export const LAYOUT_MODE = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
};
