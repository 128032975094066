import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import JSBI from 'jsbi';
import { parseUnits } from '@ethersproject/units';
import multicall from '@/utils/multicall';
import erc20 from '@/data/abi/erc20.json';
import {
  getErc20Contract,
  getFarmingContract,
  getMinterContract,
  getMinterTvlLevels,
  getMinterAprWeights,
  transactionWithEstimatedGas,
} from '@/helpers/contract.helper';
import { dateNowInSeconds } from '@/helpers/utils';

import { BN_ZERO, ethersToJSBI, SOLIDITY_TYPE_MAXIMA, SolidityType } from '@/sdk/constants';
import { BOT_USD_DECIMALS } from '@/helpers/decimals-env';
import { Farm } from '@/sdk/entities/farm';
import { TokenAmount } from '@/sdk/entities/fractions/tokenAmount';
import { Portfolio } from '@/sdk/entities/portfolio';
import { PortfolioFarm } from '@/sdk/entities/portfolioFarm';
import { ethersToBigNumber } from '@/utils/bigNumber';
import BigNumber from 'bignumber.js';
import { BIG_ZERO, BIG_TEN } from '@/utils/bigNumber';
import { fromWei } from '@/sdk/utils';
import { fetchFees } from '@/store/modules/portfolios/portfolio-api';
import { getPriceInUSD, fetchPricesInUSDByAddresses } from '@/helpers/backend-api';
import { NETWORK_BACKEND_URL } from '@/helpers/networkParams.helper';
import { getTotalSummary } from '@/helpers/analytic-api';
import { useEVMWallet } from '@/store/modules/wallet/useEVMWallet';
import { useTokens } from '@/store/modules/tokens/useTokens';

export const FARMING_ACTION_TYPES = {
  FARMING_INIT: 'farmingInit',
  GET_STATUS_FARMS: 'getStatusFarms',
  GET_TVL_LEVELS: 'getTvlLevels',
  GET_CURRENT_TOTAL_TVL: 'getCurrentTotalTvl',
  GET_APR_WEIGHTS: 'getAprWeights',
  GET_FARM_PRICES: 'getFarmPrices',
  GET_FARMING_LP: 'getFarmingLp',
  GET_PORTFOLIO_FARMING_LP: 'getPortfolioFarmingLp',
  GET_BLUES_PRICE: 'getBluesPrice',
  CHECK_ALLOWANCE: 'checkAllowance',
  FARMING_STAKE_LP: 'farmingStakeLp',
  FARMING_UNSTAKE_LP: 'farmingUnStakeLp',
  FARMING_HARVEST: 'farmingHarvest',
};
export const FARMING_MUTATION_TYPES = {
  SET_STATE: 'setState',
  FARMING_ADD_LP: 'farmingAddLp',
  FARMING_ADD_BLUES_PRICE: 'farmingAddBluesPrice',
};

const state = {
  isStoreReady: false,
  hasError: false,
  statusFarms: [],
  lp: {},
  bluesPrice: 0,
  tvlLevels: [],
  tvlAmount: 0,
  aprWeights: [],
};
const getters = {
  getFarmingTableData: state => {
    const tableDataArray = Object.values(state.lp || {});
    if (tableDataArray.length > 0) {
      return tableDataArray;
    }
    return [];
  },
};
const actions = {
  async [FARMING_ACTION_TYPES.FARMING_INIT]({ state, commit, dispatch }) {
    commit(FARMING_MUTATION_TYPES.SET_STATE, { isStoreReady: false, lp: {}, statusFarms: [] });
    dispatch(FARMING_ACTION_TYPES.GET_TVL_LEVELS);
    dispatch(FARMING_ACTION_TYPES.GET_APR_WEIGHTS);
    await dispatch(FARMING_ACTION_TYPES.GET_STATUS_FARMS);
    await Promise.all([
      dispatch(FARMING_ACTION_TYPES.GET_FARMING_LP),
      dispatch(FARMING_ACTION_TYPES.GET_PORTFOLIO_FARMING_LP),
    ]);
    console.log('lp', state.lp);
    commit(FARMING_MUTATION_TYPES.SET_STATE, { isStoreReady: true });
  },
  async [FARMING_ACTION_TYPES.GET_STATUS_FARMS]({ commit }) {
    console.log('GET_FARMS_START');
    const { walletState } = useEVMWallet();
    try {
      const minterContract = getMinterContract();
      const statusFarms = await minterContract.getStatusFarms(
        walletState.account,
        dateNowInSeconds(),
      );
      commit(FARMING_MUTATION_TYPES.SET_STATE, { statusFarms });
    } catch (e) {
      console.debug(e);
    }
    console.log('GET_FARMS_END');
  },
  async [FARMING_ACTION_TYPES.GET_TVL_LEVELS]({ commit }) {
    try {
      const tvlLevels = await getMinterTvlLevels();
      commit(FARMING_MUTATION_TYPES.SET_STATE, { tvlLevels });
    } catch (e) {
      console.debug(e);
    }
  },
  async [FARMING_ACTION_TYPES.GET_CURRENT_TOTAL_TVL]({ commit }) {
    try {
      const response = await getTotalSummary();
      const tvlAmount = new BigNumber(response?.tvlUSD ?? 0).toFixed(0);

      commit(FARMING_MUTATION_TYPES.SET_STATE, { tvlAmount });
    } catch (e) {
      console.debug('[FARMING:CURRENT:TOTAL:TVL: Can not load current total tvl : ', e);
    }
  },
  async [FARMING_ACTION_TYPES.GET_APR_WEIGHTS]({ commit }) {
    try {
      const aprWeights = await getMinterAprWeights();
      commit(FARMING_MUTATION_TYPES.SET_STATE, { aprWeights });
    } catch (e) {
      console.debug(e);
    }
  },
  async [FARMING_ACTION_TYPES.GET_FARMING_LP]({ state, rootState, commit }) {
    console.log('GET_LP_START');
    const { getBLUESToken } = useTokens();
    try {
      const pairs = rootState.routes.pairs;
      await Promise.all(
        state.statusFarms.map(async status => {
          const pair = pairs.find(pair => pair.tokenAddress === status.token);
          if (!pair) return;

          console.warn('[WARNING] NEED CHECK CASE THEN THIS CODE IS EXECUTING!');

          const nativeToken = getBLUESToken();
          const prices = await fetchPricesInUSDByAddresses([
            pair.token0.address,
            pair.token1.address,
            nativeToken.address,
          ]);

          const feeRequest = await fetch(`${NETWORK_BACKEND_URL + 'pool/getFee'}`, {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
              pools: [status.token],
              period: 30,
            }),
          });
          const feeResponse = await feeRequest.json();
          console.log('feeResponse', status.token, feeResponse);

          const nativeTokenPriceInBase = new BigNumber(prices[2]).dividedBy(
            BIG_TEN.pow(nativeToken.decimals),
          );
          const newFarm = new Farm(
            status,
            pair,
            [
              new TokenAmount(pair.token0, prices[0] ?? BN_ZERO),
              new TokenAmount(pair.token1, prices[1] ?? BN_ZERO),
            ],
            nativeTokenPriceInBase,
            feeResponse.fee[0],
          );
          commit(FARMING_MUTATION_TYPES.FARMING_ADD_LP, newFarm);
        }),
      );
    } catch (e) {
      console.debug(e);
    }
    console.log('GET_LP_END');
  },
  async [FARMING_ACTION_TYPES.GET_PORTFOLIO_FARMING_LP]({ state, rootState, commit }) {
    console.log('GET_PORTFOLIO_LP_START');
    const { getBLUESToken } = useTokens();
    try {
      const nativeToken = getBLUESToken();
      const portfolios: Portfolio[] = Object.values(rootState.portfolios.portfolios);

      const lpTokenAddressToFeeMap = {};

      const tokenAddressToPriceMap = {};

      const tokenMintAddressPriceMap = {};
      tokenAddressToPriceMap[nativeToken.address] = BIG_ZERO;

      state.statusFarms.forEach(status => {
        const portfolio = portfolios.find(portfolio => portfolio.lpTokenAddress === status.token);
        if (!portfolio) return;

        lpTokenAddressToFeeMap[portfolio.lpTokenAddress] = '0';
        tokenAddressToPriceMap[portfolio.baseTokenAddress] = BIG_ZERO;
        tokenMintAddressPriceMap[status.tokenToMint] = BIG_ZERO;
      });

      const lpTokenAddresses = Object.keys(lpTokenAddressToFeeMap);
      const portfolioBaseTokens = Object.keys(tokenAddressToPriceMap);
      const portfolioMintTokens = Object.keys(tokenMintAddressPriceMap);
      const [fees, prices, pricesMint] = await Promise.all([
        fetchFees(lpTokenAddresses),
        fetchPricesInUSDByAddresses(portfolioBaseTokens),
        fetchPricesInUSDByAddresses(portfolioMintTokens),
      ]);
      lpTokenAddresses.forEach((lpTokenAddress, index) => {
        lpTokenAddressToFeeMap[lpTokenAddress] = fees[index];
      });
      portfolioBaseTokens.forEach((tokenAddress, index) => {
        tokenAddressToPriceMap[tokenAddress] = prices[index];
      });
      portfolioMintTokens.forEach((mintAddress, index) => {
        tokenMintAddressPriceMap[mintAddress] = pricesMint[index];
      });

      const { walletState } = useEVMWallet();
      await Promise.all(
        state.statusFarms.map(async status => {
          const portfolio = portfolios.find(portfolio => portfolio.lpTokenAddress === status.token);
          if (!portfolio) return;

          // TODO: we use this token like USD token (USD decimals for `getPrices` API)
          // const baseToken = rootGetters[GLOBAL_GETTERS.GET_BASE_TOKEN];

          const callsPortfolioTokensOwned = [portfolio.lpTokenAddress].map(lpTokenAddress => ({
            address: lpTokenAddress,
            name: 'balanceOf',
            params: [walletState.account],
          }));

          const callsTotalSupply = [portfolio.lpTokenAddress].map(lpTokenAddress => ({
            address: lpTokenAddress,
            name: 'totalSupply',
          }));

          const [rawPortfolioTokensOwned, rawTotalSupply] = await Promise.all([
            multicall(erc20, callsPortfolioTokensOwned),
            multicall(erc20, callsTotalSupply),
          ]);

          // price: BLUES (native token) in USD
          const nativeTokenPriceInBase = fromWei(
            tokenAddressToPriceMap[nativeToken.address],
            BOT_USD_DECIMALS,
          );

          // price: portfolio base token in USD
          const portfolioBaseTokenPriceInUSD = fromWei(
            tokenAddressToPriceMap[portfolio.baseTokenAddress],
            BOT_USD_DECIMALS,
          );

          // price: portfolio mint token in USD
          const portfolioMintTokenPriceInUSD = fromWei(
            tokenMintAddressPriceMap[status.tokenToMint],
            BOT_USD_DECIMALS,
          );

          const newPortfolioFarm = new PortfolioFarm(
            status,
            portfolio,
            nativeTokenPriceInBase,
            lpTokenAddressToFeeMap[portfolio.lpTokenAddress],
            ethersToJSBI(rawPortfolioTokensOwned[0]),
            ethersToBigNumber(rawTotalSupply[0]),
            portfolioBaseTokenPriceInUSD,
            portfolioMintTokenPriceInUSD,
          );
          commit(FARMING_MUTATION_TYPES.FARMING_ADD_LP, newPortfolioFarm);
        }),
      );
    } catch (e) {
      console.debug('[GET_PORTFOLIO_FARMING_LP] Error: ', e);
    }
    console.log('GET_PORTFOLIO_LP_END');
  },
  async [FARMING_ACTION_TYPES.GET_BLUES_PRICE]({ commit }) {
    const { getBLUESToken } = useTokens();

    try {
      const bluesTokenSymbol = getBLUESToken().symbol ?? 'BLUES';
      const bluesPriceInUSDWei = await getPriceInUSD(bluesTokenSymbol);
      const bluesTokenPriceInUSD = fromWei(bluesPriceInUSDWei, BOT_USD_DECIMALS);

      console.log('[GET_BLUES_PRICE] BLUES price in USD : ', bluesTokenPriceInUSD.toString());
      commit(FARMING_MUTATION_TYPES.FARMING_ADD_BLUES_PRICE, bluesTokenPriceInUSD);
    } catch (ex) {
      console.error('[GET_BLUES_PRICE:ERROR] Error : ', ex);
    }
  },
  async [FARMING_ACTION_TYPES.CHECK_ALLOWANCE](_, { farm, amount }): Promise<void> {
    try {
      if (!getInstance()?.web3) {
        console.warn('[FARMING:CHECK:ALLOWANCE] Can not get signer.');
        return;
      }

      const lpTokenContract = getErc20Contract(
        farm.liquidityToken.address,
        getInstance().web3.getSigner(),
      );
      const { walletState } = useEVMWallet();
      const allowance = await lpTokenContract.allowance(walletState.account, farm.farm);
      const jsbiAllowance = ethersToJSBI(allowance);
      const jsbiAmount = ethersToJSBI(parseUnits(amount ?? '0', farm.liquidityToken.decimals));

      const needApprove =
        JSBI.equal(jsbiAllowance, BN_ZERO) || JSBI.greaterThan(jsbiAmount, jsbiAllowance);
      console.groupCollapsed(
        `[FARMING:CHECK:ALLOWANCE] Need approve ${farm.liquidityToken.symbol} : ${needApprove}`,
      );
      console.log(`${farm.liquidityToken.symbol}     input: `, jsbiAmount.toString());
      console.log(`${farm.liquidityToken.symbol} allowance: `, jsbiAllowance.toString());
      console.groupEnd();

      if (needApprove) {
        const approveRequest = await transactionWithEstimatedGas(lpTokenContract, 'approve', [
          farm.farm,
          SOLIDITY_TYPE_MAXIMA[SolidityType.uint256].toString(),
        ]);

        await approveRequest.wait();
      }
    } catch (ex) {
      console.debug('[FARMING:CHECK:ALLOWANCE:ERROR] Error: ', ex);
      throw ex;
    }
  },
  async [FARMING_ACTION_TYPES.FARMING_STAKE_LP](_, { farm, amount }) {
    console.log('stake', farm, amount);
    try {
      const farmingContract = getFarmingContract(farm.farm, getInstance().web3.getSigner());

      const bigIntAmount = parseUnits(amount, farm.liquidityToken.decimals).toString();

      const result = await transactionWithEstimatedGas(farmingContract, 'deposit', [bigIntAmount]);

      await result.wait().then(function (result) {
        Promise.resolve(result);
      });
    } catch (e) {
      console.debug(e);
      await Promise.reject(e);
    }
  },
  async [FARMING_ACTION_TYPES.FARMING_UNSTAKE_LP](_, { farm, amount }) {
    console.log('FARMING_UNSTAKE_LP', farm, amount);
    try {
      const farmingContract = getFarmingContract(farm.farm, getInstance().web3.getSigner());

      const bigIntAmount = parseUnits(amount, farm.liquidityToken.decimals).toString();

      const result = await transactionWithEstimatedGas(farmingContract, 'withdraw', [bigIntAmount]);

      await result.wait().then(function (result) {
        Promise.resolve(result);
      });
    } catch (e) {
      console.debug(e);
      await Promise.reject(e);
    }
  },
  async [FARMING_ACTION_TYPES.FARMING_HARVEST](_, { farm }) {
    console.log('FARMING_HARVEST', farm);
    try {
      const farmingContract = getFarmingContract(farm.farm, getInstance().web3.getSigner());

      const result = await transactionWithEstimatedGas(farmingContract, 'getReward', []);

      await result.wait().then(function (result) {
        Promise.resolve(result);
      });
    } catch (e) {
      console.debug(e);
      await Promise.reject(e);
    }
  },
};
const mutations = {
  [FARMING_MUTATION_TYPES.SET_STATE](_state, payload) {
    Object.keys(payload).forEach(key => {
      _state[key] = payload[key];
    });
  },
  [FARMING_MUTATION_TYPES.FARMING_ADD_LP](_state, payload) {
    _state.lp[payload.token] = payload;
  },
  [FARMING_MUTATION_TYPES.FARMING_ADD_BLUES_PRICE](_state, payload) {
    _state.bluesPrice = payload;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
