import { ethers } from 'ethers';
import erc20 from '@/data/abi/erc20.json';
import weth from '@/data/abi/wbnb.json';
import IPortfolio from '@/data/abi/IPortfolio.json';
import { simpleRpcProvider } from '@/helpers/provider.helper';
import {
  getAutoPoolAddress,
  getMinterAddress,
  getMultiCallAddress,
  getPoolFactoryAddress,
  getPortfolioAndPairRegistryAddress,
} from '@/helpers/address.helper';
import { ethersToBigNumber } from '@/utils/bigNumber';
import poolFactoryAbi from '@/data/abi/PoolFactory.json';
import routerAbi from '@/data/abi/Router.json';
import minterAbi from '@/data/abi/MinterV3.json';
import farmingAbi from '@/data/abi/FarmingV4.json';
import portfolioAbi from '@/data/abi/PortfolioAndPairRegistry.json';
import multiCallAbi from '@/data/abi/Multicall.json';
import delayedExchangePoolAbi from '@/data/abi/LiquifiDelayedExchangePool.json';
import stakingAbi from '@/data/abi/IStaking.json';
import autoPoolAbi from '@/data/abi/IAutoStaking.json';
import liquidityDelegateAbi from '@/data/abi/LiquidityDelegate.json';
import { DEFAULT_TOKEN_DECIMAL } from '@/sdk/constants';
import { ENABLE_FAKE_CARDANO_NETWORK } from './fakeCardanoNetwork';

const getContract = (
  abi: any,
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  const signerOrProvider = signer ?? simpleRpcProvider;
  return new ethers.Contract(address, abi, signerOrProvider);
};

export const getErc20Contract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(erc20, address, signer);
};

export const getWethContract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(weth, address, signer);
};

export const getPoolFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(poolFactoryAbi.abi, getPoolFactoryAddress(), signer);
};

export const getDelayedExchangePoolContract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(delayedExchangePoolAbi.abi, address, signer);
};

export const getRouterContract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(routerAbi.abi, address, signer);
};

export const getMinterContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(minterAbi.abi, getMinterAddress(), signer);
};

export const getMinterTvlLevels = async () => {
  const minterContract = getMinterContract();

  const tvlLevels = await minterContract.getTvlLevels();
  return tvlLevels.map(level => ethersToBigNumber(level).dividedBy(DEFAULT_TOKEN_DECIMAL));
};

export const getMinterAprWeights = async () => {
  const minterContract = getMinterContract();

  const aprWeights = await minterContract.getAprWeights();
  return aprWeights.map(aprWeight => aprWeight / 100);
};

export const getFarmingContract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(farmingAbi.abi, address, signer);
};

export const getStakingContract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(stakingAbi.abi, address, signer);
};

export const getPortfolioAndPairRegistryContract = (
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(portfolioAbi.abi, getPortfolioAndPairRegistryAddress(), signer);
};

export const getPortfolioContract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(IPortfolio.abi, address, signer);
};

export const getMultiCallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(multiCallAbi, getMultiCallAddress(), signer);
};

export const getAutoPoolContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(autoPoolAbi.abi, getAutoPoolAddress(), signer);
};

export const getLiquidityDelegateContract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(liquidityDelegateAbi.abi, address, signer);
};

const MIN_GAS_LIMIT_IN_CARDANO = 500_000;

export const transactionWithEstimatedGas = async (
  contract: ethers.Contract,
  methodName: string,
  args: any[],
  overrides?: { value: string | number },
): Promise<ethers.providers.TransactionResponse> => {
  const gasEstimated = overrides
    ? await contract.estimateGas[methodName](...args, overrides)
    : await contract.estimateGas[methodName](...args);
  let gasLimit = gasEstimated.mul(Number(process.env.VUE_APP_GAS_LIMIT_OVERHEAD)).div(100);

  console.groupCollapsed(`[ESTIMATE:GAS] '${methodName}' `);
  console.log('gas limit : ', gasLimit.toString());
  if (ENABLE_FAKE_CARDANO_NETWORK && gasLimit.lt(MIN_GAS_LIMIT_IN_CARDANO)) {
    gasLimit = gasLimit.add(MIN_GAS_LIMIT_IN_CARDANO);
    console.log('gas limit when WSC : ', gasLimit.toString());
  }
  console.groupEnd();

  return contract[methodName](...args, { gasLimit, value: overrides?.value });
};
