import { computed, reactive, ref } from 'vue';
import { defineStore } from 'pinia';
import { APP_THEME, BREAKPOINTS, LAYOUT_MODE } from '@/constants/LAYOUT_PARAMS';
import { useLocalStorage } from '@/composables/useLocalStorage';

export const useLayout = defineStore('layout', () => {
  const layout = reactive({
    isMobile: false,
    isTablet: false,
    isDesktop: true,
    view: 'desktop',
  });

  const { getItem, setItem } = useLocalStorage();

  const theme = ref<string>(getItem('theme') || APP_THEME.DARK);
  const isBigScreen = ref<boolean>(window.innerWidth > BREAKPOINTS.XXL);
  const cls = Object.values(LAYOUT_MODE);

  const setIsBigScreen = () => (isBigScreen.value = window.innerWidth > BREAKPOINTS.XXL);

  const setLayout = () => {
    if (window.innerWidth < BREAKPOINTS.SM) {
      layout.isMobile = true;
      layout.isTablet = false;
      layout.isDesktop = false;
      layout.view = LAYOUT_MODE.MOBILE;
    } else {
      if (window.innerWidth >= BREAKPOINTS.SM && window.innerWidth < BREAKPOINTS.XL) {
        layout.isTablet = true;
        layout.isMobile = false;
        layout.isDesktop = false;
        layout.view = LAYOUT_MODE.TABLET;
      } else {
        layout.isDesktop = true;
        layout.isTablet = false;
        layout.isMobile = false;
        layout.view = LAYOUT_MODE.DESKTOP;
      }
      document.body.classList.remove(...cls);
      document.body.classList.add(layout.view);
      setIsBigScreen();
    }
  };
  const setTheme = () => {
    document.documentElement.dataset.theme = theme.value;
    setItem('theme', theme.value);
  };
  const switchTheme = () => {
    theme.value = theme.value === APP_THEME.DARK ? APP_THEME.LIGHT : APP_THEME.DARK;
    setTheme();
  };

  return {
    setLayout,
    isMobile: computed(() => layout.isMobile),
    isTablet: computed(() => layout.isTablet),
    isDesktop: computed(() => layout.isDesktop),
    layout: computed(() => layout.view),
    isBigScreen,
    setIsBigScreen,
    theme,
    switchTheme,
    setTheme,
  };
});
