// HTTP connection to the API
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';
import { provideApolloClient } from '@vue/apollo-composable';
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: `${String(process.env.VUE_APP_ANALYTIC_BACKEND_URL)}graphql`,
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
  connectToDevTools: true,
});

provideApolloClient(apolloClient);

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

export default apolloProvider;
