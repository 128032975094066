import BigNumber from 'bignumber.js';
import { LP_TOKEN_DECIMALS } from '@/sdk/entities/constants/LP_TOKEN_DECIMALS';
import { Portfolio, WithdrawTokens } from '@/sdk/entities/portfolio';
import { PortfolioSource } from '@/sdk/entities/PortfolioSource';
import { EasyModeForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/models/easy-mode-form';
import { getPortfolioAndPairRegistryContract } from './contract.helper';
import { estimateWithdraw } from './cross-chain-api';
import { Token } from '@/sdk/entities/token';
import { compareTokenAddresses, toWei } from '@/sdk/utils';
import { EasyModeWithdrawToken } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/models/easy-mode-token';

export function getWithdrawMethodTokenParameters(
  withdrawTokens: { token: Token; amount: BigNumber }[],
  LPSum: BigNumber,
  easyModeForm: EasyModeForm,
): {
  tokens: Token[];
  amountsOutInWei: string[];
  amountsLPOutInWei: string[];
  portfolioAddress: string;
} {
  const tokens: Token[] = [];
  const amountsLPOutInWei: string[] = [];
  const amountsOutInWei: string[] = [];

  let roundedLptAmountsSum = new BigNumber(0);

  withdrawTokens.forEach(withdrawToken => {
    const token = withdrawToken.token;
    const amountOut = toWei(withdrawToken.amount, token.decimals).toFixed();
    // LP
    const formToken = (easyModeForm.tokens as EasyModeWithdrawToken[]).find(formToken =>
      compareTokenAddresses(formToken.address, token.address),
    )!;
    const roundedWeiToWithdraw = formToken.lpWeiToWithdraw.integerValue(BigNumber.ROUND_DOWN);
    roundedLptAmountsSum = roundedLptAmountsSum.plus(roundedWeiToWithdraw);
    const amountLPOut = roundedWeiToWithdraw.toFixed();

    tokens.push(token);
    amountsOutInWei.push(amountOut);
    amountsLPOutInWei.push(amountLPOut);
  });

  const dust = toWei(LPSum).minus(roundedLptAmountsSum);
  amountsLPOutInWei[0] = new BigNumber(amountsLPOutInWei[0])
    .plus(dust)
    .integerValue(BigNumber.ROUND_DOWN)
    .toFixed();

  return {
    tokens,
    amountsOutInWei,
    amountsLPOutInWei,
    portfolioAddress: easyModeForm.portfolio.contractAddress,
  };
}

export function getWithdrawMethodParameters(
  portfolio: Portfolio,
  easyModeForm?: EasyModeForm,
): {
  tokenAddresses: string[];
  lptAmounts: string[];
  portfolioAddress: string;
  easyModeForm?: EasyModeForm;
} {
  const tokenAddresses: string[] = [];
  const lptAmounts: string[] = [];

  const checkedTokens = Object.values<WithdrawTokens>(portfolio.withdrawTokens).filter(
    token => token.checked,
  );
  checkedTokens.forEach(token => {
    const lpTAmountWei = token.lpWeiValue;
    if (!token.lpWeiValue) {
      throw new Error('Withdraw token lp value is null');
    }

    // NOTE: We are filtering tokens with zero amount.
    if (lpTAmountWei.isZero()) {
      return;
    }

    tokenAddresses.push(token.tokenAddress);
    lptAmounts.push(lpTAmountWei!.toFixed(0));
  });

  return {
    tokenAddresses,
    lptAmounts,
    portfolioAddress: portfolio.contractAddress,
    easyModeForm,
  };
}

export async function fetchExactTokenWeiAmountsAfterWithdraw(
  portfolio: Portfolio,
  easyModeForm: EasyModeForm,
  parameters?: {
    tokenAddresses: string[];
    lptAmounts: string[];
    portfolioAddress: string;
  },
): Promise<{
  estimatedAmountsInTokenWeiByTokensAddresses: Record<string, string>;
  crossChainFeeInLPWei?: string;
}> {
  parameters ||= getWithdrawMethodParameters(portfolio, easyModeForm);

  let bnAmounts: string[];
  let crossChainFeeInLPWei: string | undefined = undefined;
  console.groupCollapsed('[WITHDRAW:ESTIMATE] fetchExactTokenWeiAmountsAfterWithdraw');
  console.log('params : ', parameters);
  console.groupEnd();

  if (portfolio.type === PortfolioSource.PORTFOLIO_LOCALE) {
    const registryContract = getPortfolioAndPairRegistryContract();
    // NOTE: LOCAL: amounts in token`s decimals
    bnAmounts = await registryContract.callStatic.estimateWithdraw(
      parameters.tokenAddresses,
      parameters.lptAmounts,
      parameters.portfolioAddress,
    );
    bnAmounts = bnAmounts.map(amount => amount.toString());
  } else {
    // TODO: need add throw Error when get data about incorrect request.
    // NOTE: CROSSCHAIN: amounts in token`s decimals
    const response = await estimateWithdraw(
      portfolio.portfolioId,
      parameters.tokenAddresses
        .map(tokenAddress => {
          // NOTE: token symbols - should be crosschain symbols
          return portfolio.tokensInfoByAddr[tokenAddress].token.crossChainSymbol;
        })
        .join(','),
      parameters.lptAmounts.join(','),
      easyModeForm.sourceChainId,
      easyModeForm.destinationChainId,
    );
    bnAmounts = response.coins.map(coin => coin.amount);
    crossChainFeeInLPWei = response.feeAmountInLp;
  }

  loggingFetchExactTokenWeiAmountsAfterWithdraw(
    portfolio,
    parameters,
    bnAmounts,
    crossChainFeeInLPWei,
  );

  const estimatedAmountsInTokenWeiByTokensAddresses = {};
  parameters.tokenAddresses.forEach((tokenAddresses, index) => {
    estimatedAmountsInTokenWeiByTokensAddresses[tokenAddresses] = bnAmounts[index].toString();
  });
  return {
    estimatedAmountsInTokenWeiByTokensAddresses,
    crossChainFeeInLPWei,
  };
}

// DEBUG

function loggingFetchExactTokenWeiAmountsAfterWithdraw(
  portfolio: Portfolio,
  parameters: {
    tokenAddresses: string[];
    lptAmounts: string[];
    portfolioAddress: string;
  },
  bnAmounts: string[],
  crossChainFeeInLPWei: string | undefined,
) {
  console.groupCollapsed(
    '[WITHDRAW:ESTIMATE] fetchExactTokenWeiAmountsAfterWithdraw bnAmounts :',
    bnAmounts.map(amount => amount.toString()),
  );
  console.table(
    parameters.tokenAddresses.map((tokenAddress, index) => {
      const token = portfolio.tokensInfoByAddr[tokenAddress].token;
      return {
        token: token.symbol,
        'estimated amount [ TOKEN ]': BigNumber(bnAmounts[index] ?? 0)
          .shiftedBy(-token.decimals)
          .toString(),
        'estimated amount [ TOKEN WEI ]': bnAmounts[index].toString(),
      };
    }),
  );
  console.table({
    'cross chain fee [ LP WEI ]': crossChainFeeInLPWei,
    'cross chain fee [ LP ]': crossChainFeeInLPWei
      ? BigNumber(crossChainFeeInLPWei).shiftedBy(-LP_TOKEN_DECIMALS).toString()
      : crossChainFeeInLPWei,
  });
  console.groupEnd();
}
