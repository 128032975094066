import BigNumber from 'bignumber.js';
import {
  BLOCKS_PER_YEAR,
  CLAIMS_IN_YEAR,
  DEFAULT_TOKEN_DECIMAL,
  RATIO_TO_SHARE,
} from '@/sdk/constants';
import { ethers } from 'ethers';
import { BIG_ONE, BIG_100, ethersToBigNumber, BIG_ZERO } from '@/utils/bigNumber';
import { StakingPool } from './stakingPool';

const LOGGER = {
  groupCollapsed: (...label: any[]) => {
    if (isLoggingDisabled()) return;

    console.groupCollapsed(...label);
  },
  groupEnd: () => {
    if (isLoggingDisabled()) return;

    console.groupEnd();
  },
  log: (message?: any, ...optionalParams: any[]) => {
    if (isLoggingDisabled()) return;

    console.log(message, ...optionalParams);
  },
};

export class AutoStaking implements StakingPool {
  public readonly rewardPerBlock: BigNumber;
  public readonly rewardPerClaim: BigNumber;
  public readonly accDeposited: BigNumber;
  public readonly tokensWithCompounding: BigNumber;
  public readonly totalCompound: BigNumber;
  public readonly tokensAtLastUserAction: BigNumber;
  public readonly token: string;
  public readonly farm: string;
  public readonly aprWeights: Array<number>;
  public readonly newRewards: BigNumber;
  public readonly hodlerAPR: BigNumber;
  public readonly availableCompound: BigNumber;
  public readonly availableWithCompoundTotal: BigNumber;
  public readonly divisor: BigNumber;
  public readonly nativeTokenPriceInBase: BigNumber;
  public readonly userTimeFeeEnd: BigNumber;
  public readonly withdrawFee: BigNumber;

  private readonly performanceFee: number;
  private readonly callFee: number;
  public readonly tokenToMint: string;
  public readonly isSyrupPool: boolean;

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    statusAutoStaking: object,
    nativeTokenPriceInBase: BigNumber,
    farm: string,
    aprWeights: Array<number>,
    newRewards: ethers.BigNumber,
    hodlerAPR: ethers.BigNumber,
    totalCompound: ethers.BigNumber,
    availableCompound: ethers.BigNumber,
    availableWithCompoundTotal: ethers.BigNumber,
    divisor: ethers.BigNumber,
  ) {
    LOGGER.log('AutoStaking.ts', statusAutoStaking);
    this.rewardPerBlock = ethersToBigNumber(statusAutoStaking['rewardPerBlock']);
    this.rewardPerClaim = ethersToBigNumber(statusAutoStaking['rewardPerClaim']);
    this.accDeposited = ethersToBigNumber(statusAutoStaking['accDeposited']);
    this.totalCompound = ethersToBigNumber(totalCompound);
    this.availableCompound = ethersToBigNumber(availableCompound || 0);
    this.availableWithCompoundTotal = ethersToBigNumber(availableWithCompoundTotal || 0);
    this.tokensWithCompounding = ethersToBigNumber(statusAutoStaking['tokensWithCompounding']);
    this.tokensAtLastUserAction = ethersToBigNumber(statusAutoStaking['tokensAtLastUserAction']);
    this.performanceFee = statusAutoStaking['performanceFee'] / RATIO_TO_SHARE; // it is share
    this.callFee = statusAutoStaking['callFee'] / RATIO_TO_SHARE; // it is share
    this.token = statusAutoStaking['token'];
    this.farm = farm;
    this.aprWeights = aprWeights;
    this.newRewards = ethersToBigNumber(newRewards);
    this.hodlerAPR = ethersToBigNumber(hodlerAPR);
    this.divisor = ethersToBigNumber(divisor);
    this.nativeTokenPriceInBase = nativeTokenPriceInBase; // in USD
    this.userTimeFeeEnd = ethersToBigNumber(statusAutoStaking['userTimeFeeEnd']);
    this.withdrawFee = ethersToBigNumber(statusAutoStaking['withdrawFee']).dividedBy(BIG_100);
    this.tokenToMint = statusAutoStaking['token'];
    this.isSyrupPool = false;
  }

  /**
   * Claim in BLUES (native pool token)
   */
  public get printClaim(): BigNumber {
    // TODO: should be BLUES decimals
    return this.rewardPerClaim.dividedBy(DEFAULT_TOKEN_DECIMAL);
  }

  /**
   * Claim in USD
   */
  public get printClaimUSD(): BigNumber {
    return this.printClaim.multipliedBy(this.nativeTokenPriceInBase);
  }

  public get printApr(): BigNumber {
    const apr = this.rewardPerBlock
      .multipliedBy(BLOCKS_PER_YEAR)
      .multipliedBy(1 - this.callFee)
      .multipliedBy(1 - this.performanceFee)
      .dividedBy(this.accDeposited)
      .multipliedBy(100);
    LOGGER.log('auto:apr', apr.valueOf());
    return isNaN(Number(apr.valueOf())) || !apr.isFinite() ? new BigNumber(0) : apr;
  }

  public get printApy(): BigNumber {
    const aprL = this.hodlerAPR.dividedBy(DEFAULT_TOKEN_DECIMAL);

    const aprWeights = this.aprWeights.map(aprWeight => aprWeight / 100);

    const apr = this.rewardPerBlock
      .multipliedBy(1 - this.callFee)
      .multipliedBy(1 - this.performanceFee)
      .multipliedBy(BLOCKS_PER_YEAR)
      .dividedBy(this.accDeposited);

    const apr1 = apr.multipliedBy(aprWeights[0]);
    const apr2 = apr.multipliedBy(aprWeights[1] + aprWeights[2]);

    const N = CLAIMS_IN_YEAR;

    BigNumber.config({ POW_PRECISION: 100 });

    const APY = BIG_ONE.plus(apr1.dividedBy(N))
      .pow(N)
      .plus(
        apr2.dividedBy(apr1).multipliedBy(BIG_ONE.plus(apr1.dividedBy(N)).pow(N).minus(BIG_ONE)),
      )
      .minus(BIG_ONE)
      .plus(aprL.multipliedBy(BLOCKS_PER_YEAR))
      .multipliedBy(100);

    LOGGER.log('auto:apy', APY.toString());
    return APY.isNaN() ? BIG_ZERO : APY;
  }

  public get printTotalStake(): BigNumber {
    return this.availableWithCompoundTotal.dividedBy(DEFAULT_TOKEN_DECIMAL);
  }

  public get printYouEarned(): BigNumber {
    return this.newRewards.dividedBy(DEFAULT_TOKEN_DECIMAL);
  }

  public get printYouEarnedUSD(): BigNumber {
    return this.printYouEarned.multipliedBy(this.nativeTokenPriceInBase);
  }

  public get printHodlerAPR(): BigNumber {
    return this.hodlerAPR.multipliedBy(BLOCKS_PER_YEAR).dividedBy(DEFAULT_TOKEN_DECIMAL);
  }

  public get printYouStaked(): BigNumber {
    return this.tokensWithCompounding.dividedBy(DEFAULT_TOKEN_DECIMAL);
  }

  public get printYouStakedUSD(): BigNumber {
    return this.printYouStaked.multipliedBy(this.nativeTokenPriceInBase);
  }

  public get printTotalCompound(): BigNumber {
    return this.totalCompound.dividedBy(DEFAULT_TOKEN_DECIMAL);
  }

  public get printTotalCompoundUSD(): BigNumber {
    return this.printTotalCompound.multipliedBy(this.nativeTokenPriceInBase);
  }

  public get printAvailableCompound(): BigNumber {
    return this.availableCompound.dividedBy(DEFAULT_TOKEN_DECIMAL);
  }

  public get printAvailableCompoundUSD(): BigNumber {
    return this.printAvailableCompound.multipliedBy(this.nativeTokenPriceInBase);
  }

  public calculateUSD(value: string): BigNumber {
    return new BigNumber(value === '' ? 0 : value).multipliedBy(this.nativeTokenPriceInBase);
  }
}

// DEBUG

function isLoggingDisabled() {
  return !window['BLUESHIFT_DEBUG'].AUTO_POOLS;
}
