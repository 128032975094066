import LockConnector from '@snapshot-labs/lock/src/connector';

// NOTE: Copy from '@snapshot-labs/lock/connectors/injected';
export default class Connector extends LockConnector {
  get evmProvider() {
    return window['ethereum'];
  }

  async connect() {
    let provider;
    if (this.evmProvider) {
      provider = this.evmProvider;
      try {
        await this.evmProvider.request({ method: 'eth_requestAccounts' });
      } catch (e) {
        console.error(e);
        if (e.code === 4001) return;
      }
    } else if (window['web3']) {
      provider = window['web3'].currentProvider;
    }
    return provider;
  }

  async isLoggedIn() {
    if (!this.evmProvider) return false;
    let [selectedAddress] = <string[]>await this.evmProvider.request({
      method: 'eth_accounts',
    });
    if (selectedAddress) return true;
    await new Promise(r => setTimeout(r, 400));
    [selectedAddress] = <string[]>await this.evmProvider.request({ method: 'eth_accounts' });
    return !!selectedAddress;
  }
}
