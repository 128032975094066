import LockConnector from '@snapshot-labs/lock/src/connector';
import { Blockfrost, BridgeProvider } from 'crypto-sdk';
import { BLOCKFROST_API_KEYS } from '@/constants/BLOCKFROST_API_KEYS';
import { WRAPPED_SMART_CONTRACT_ORACLE_URL } from '@/constants/WRAPPED_SMART_CONTRACT_ORACLE_URL';
import { NETWORK_ENGINE } from '@/helpers/networkParams.helper';
import { HardwareWalletIsNotSupportedError } from '@/sdk/errors';

const SUPPORTED_WALLET_ADDRESS_LOCALSTORAGE_KEY = '_wallet.address';

export default class Connector extends LockConnector {
  private cardanoWallet;
  private provider;

  blockfrost: Blockfrost | undefined;
  milkomedaBridgeProvider: BridgeProvider | undefined;

  async init() {
    const cryptoSDK = await import('crypto-sdk');
    console.debug('[CRYPTO-SDK] version : ', cryptoSDK.CRYPTO_SDK_VERSION);
    await cryptoSDK.Loader.load();

    this.blockfrost = new cryptoSDK.Blockfrost(BLOCKFROST_API_KEYS);
    this.cardanoWallet = new cryptoSDK.CardanoWallet(this.blockfrost);

    this.milkomedaBridgeProvider = new cryptoSDK.MilkomedaBridgeProvider();
    this.cardanoWallet.setBridgeProvider(this.milkomedaBridgeProvider);

    const milkomedaProvider = new cryptoSDK.MilkomedaProvider(
      WRAPPED_SMART_CONTRACT_ORACLE_URL,
      NETWORK_ENGINE ?? '',
      this.cardanoWallet,
    );
    await milkomedaProvider.setup();

    // TODO: HACK
    milkomedaProvider['cryptoSdkConnector'] = this;

    this.provider = milkomedaProvider;
  }

  isAlreadySupportedWallet(currentWalletAddress: string): boolean {
    const alreadySupportedWalletAddress = localStorage.getItem(
      SUPPORTED_WALLET_ADDRESS_LOCALSTORAGE_KEY,
    );

    return currentWalletAddress === alreadySupportedWalletAddress;
  }

  async isSupportHardwareWallet(): Promise<boolean> {
    if (!this.cardanoWallet) {
      throw new Error('[CRYPTO-SDK:WALLET] Cardano wallet has not been initialized.');
    }

    const cryptoSDK = await import('crypto-sdk');

    try {
      const currentWalletAddress = await this.cardanoWallet.getChangeAddress();

      if (this.isAlreadySupportedWallet(currentWalletAddress)) {
        return true;
      }

      await this.cardanoWallet.signData(
        currentWalletAddress,
        cryptoSDK.AsciiToHex(
          'Please, sign a test message to check your wallet (nothing will be charged from your wallet)',
        ),
      );

      localStorage.setItem(SUPPORTED_WALLET_ADDRESS_LOCALSTORAGE_KEY, currentWalletAddress);
    } catch (error) {
      return false;
    }

    return true;
  }

  async connect() {
    try {
      await this.init();
      await this.login();
    } catch (error) {
      console.error('[CRYPTO-SDK:WALLET] ERROR : ', error);
      throw error;
    }

    if (!(await this.isSupportHardwareWallet())) {
      console.error('[CRYPTO-SDK:WALLET] Sorry, hardware wallets are not supported yet.');
      throw new HardwareWalletIsNotSupportedError();
    }

    return this.provider;
  }

  async login(): Promise<void> {
    if (!this.cardanoWallet) {
      throw new Error('[CRYPTO-SDK:WALLET] Cardano wallet has not been initialized.');
    }

    try {
      console.log('[CRYPTO-SDK:WALLET] Login cardano wallet : ', this.options.id);
      await this.cardanoWallet.login(this.options.id);
    } catch (error) {
      throw new Error(error);
    }
  }

  logout(): any {
    localStorage.removeItem(SUPPORTED_WALLET_ADDRESS_LOCALSTORAGE_KEY);
    return true;
  }

  async isLoggedIn() {
    return true;
  }
}
