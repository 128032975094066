import { createStore } from 'vuex';
import layout from '@/store/modules/layout/layout.module';
import tokens from '@/store/modules/tokens/tokens.module';
import routes from '@/store/modules/routes/routes.module';
import swap from '@/store/modules/swap/swap.module';
import pairs from '@/store/modules/addLiquidity/pairs.module';
import farming from '@/store/modules/farming/farming.module';
import staking from '@/store/modules/staking/staking.module';
import autoStaking from '@/store/modules/staking/autoStaking.module';
import portfolios from '@/store/modules/portfolios/portfolios.module';

export const MODULE_NAMES = {
  LAYOUT: 'layout',
  TOKENS: 'tokens',
  ROUTES: 'routes',
  SWAP: 'swap',
  PAIRS: 'pairs',
  FARMING: 'farming',
  STAKING: 'staking',
  AUTO_STAKING: 'autoStaking',
  PORTFOLIOS: 'portfolios',
};

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    [MODULE_NAMES.LAYOUT]: layout,
    [MODULE_NAMES.TOKENS]: tokens,
    [MODULE_NAMES.ROUTES]: routes,
    [MODULE_NAMES.SWAP]: swap,
    [MODULE_NAMES.PAIRS]: pairs,
    [MODULE_NAMES.FARMING]: farming,
    [MODULE_NAMES.STAKING]: staking,
    [MODULE_NAMES.AUTO_STAKING]: autoStaking,
    [MODULE_NAMES.PORTFOLIOS]: portfolios,
  },
});
