import fetchRetry from 'fetch-retry';

import { ChainId } from '@/sdk/constants';
import {
  BLUES_CROSSCHAIN_URL,
  BLUES_CROSSCHAIN_TRANSACTION_STATUS_RETRY_DELAY_IN_SEC,
} from '@/helpers/networkParams.helper';
import { TRANSACTION_STATUS_ERROR_MESSAGES } from './TRANSACTION_STATUS_ERROR_MESSAGES';
import { ICrossChainToken } from '@/store/modules/tokens/models/cross-chain-token.interface';
import { ISwapBestTrade } from '@/store/modules/swap/models/swap-best-trade.interface';

export type EstimateDepositResponse = {
  totalLiquidityOut: string;
  feeAmount: string;
  feeAmountInLp: string;
};

export type EstimateWithdrawResponse = {
  coins: {
    symbol: string;
    amount: string;
  }[];
  feeAmount: string;
  feeAmountInLp: string;
};

type Pagination = {
  next_key: string | null;
  total: string;
};

type TokensResponse = {
  pagination: Pagination;
  token: ICrossChainToken[];
};

export async function getTokens(chainId: ChainId): Promise<TokensResponse> {
  const response = await fetch(
    `${BLUES_CROSSCHAIN_URL}blues-chain/dex/tokens/chain_id/${chainId}`,
    {
      method: 'get',
      headers: { 'content-type': 'application/json' },
    },
  );
  return response.json();
}

export async function getPortfolioInfos(chainId: ChainId) {
  const response = await fetch(
    `${BLUES_CROSSCHAIN_URL}blues-chain/dex/portfolio_infos/${chainId}`,
    {
      method: 'get',
      headers: { 'content-type': 'application/json' },
    },
  );
  return response.json();
}

/**
 *
 * @param portfolioId cross-chain portfolio id
 * @param tokensIn amount + token symbol (amount in token wei units, symbol is cross-chain token symbol): 100000bUSDC
 * @param sourceChain source chain id
 * @param destinationChain destination chain id
 */
export async function estimateDeposit(
  portfolioId: number,
  tokensIn: string, // amount + token symbol
  sourceChain: ChainId,
  destinationChain: ChainId,
): Promise<EstimateDepositResponse> {
  const response = await fetch(
    `${BLUES_CROSSCHAIN_URL}blues-chain/dex/estimate_deposit/portfolio/${portfolioId}/tokens_in/${tokensIn}/source_chain/${sourceChain}/destination_chain/${destinationChain}`,
    {
      method: 'get',
      headers: { 'content-type': 'application/json' },
    },
  );

  if (response.ok) {
    return Promise.resolve(response.json() as Promise<EstimateDepositResponse>);
  }

  return Promise.reject(response.json());
}

export async function estimateWithdraw(
  portfolioId: number,
  tokens: string,
  amounts: string,
  sourceChain: ChainId,
  destinationChain: ChainId,
): Promise<EstimateWithdrawResponse> {
  const response = await fetch(
    `${BLUES_CROSSCHAIN_URL}blues-chain/dex/estimate_withdraw/portfolio/${portfolioId}/symbols_out/${tokens}/lpt_amounts_in/${amounts}/source_chain/${sourceChain}/destination_chain/${destinationChain}`,
    {
      method: 'get',
      headers: { 'content-type': 'application/json' },
    },
  );
  return response.json() as Promise<EstimateWithdrawResponse>;
}

export async function exactInRoutes(
  chainIdFrom: ChainId,
  tokenAddressFrom: string,
  chainIdTo: ChainId,
  tokenAddressTo: string,
  amountIn: string,
  maxHops: 1 | 2 | 3,
  localOnly: boolean,
  settings?: {
    signal: AbortSignal;
  },
): Promise<ISwapBestTrade> {
  const response = await fetch(
    `${BLUES_CROSSCHAIN_URL}blues-chain/dex/exact_in_routes/chain_id_from/${chainIdFrom}/token_address_from/${tokenAddressFrom}/chain_id_to/${chainIdTo}/token_address_to/${tokenAddressTo}/amount_in/${amountIn}/max_hops/${maxHops}/local_priority/${localOnly}`,
    {
      method: 'get',
      headers: { 'content-type': 'application/json' },
      signal: settings?.signal,
    },
  );
  if (response.ok) {
    return Promise.resolve(response.json());
  }
  return Promise.reject(response);
}

export async function exactOutRoutes(
  chainIdFrom: ChainId,
  tokenAddressFrom: string,
  chainIdTo: ChainId,
  tokenAddressTo: string,
  amountOut: string,
  maxHops: 1 | 2 | 3,
  localOnly: boolean,
  settings?: {
    signal: AbortSignal;
  },
): Promise<ISwapBestTrade> {
  const response = await fetch(
    `${BLUES_CROSSCHAIN_URL}blues-chain/dex/exact_out_routes/chain_id_from/${chainIdFrom}/token_address_from/${tokenAddressFrom}/chain_id_to/${chainIdTo}/token_address_to/${tokenAddressTo}/amount_out/${amountOut}/max_hops/${maxHops}/local_priority/${localOnly}`,
    {
      method: 'get',
      headers: { 'content-type': 'application/json' },
      signal: settings?.signal,
    },
  );
  if (response.ok) {
    return Promise.resolve(response.json());
  }
  return Promise.reject(response);
}

type TransactionStatus = 'Unknown' | 'Successful' | 'SuccessfulRollback' | 'Failed';

export type TransactionStatusResponse = {
  transactionStatus: {
    fromChain: ChainId;
    txHash: string;
    status: TransactionStatus;
    txHashDestination: string;
    message: string;
    creator: string;
  };
};

interface ErrorOptions {
  cause: TransactionStatusResponse;
}

export class TransactionStatusError extends Error {
  cause: TransactionStatusResponse;

  constructor(message: string, options: ErrorOptions) {
    super(message);
    this.cause = options.cause;
  }
}

export async function fetchSwapTransactionStatus(
  fromChainId: ChainId,
  txHash: string,
): Promise<TransactionStatusResponse> {
  const response = await fetchRetry(fetch)(
    `${BLUES_CROSSCHAIN_URL}blues-chain/dex/transaction_statuses/${fromChainId}/${txHash}`,
    {
      method: 'get',
      headers: { 'content-type': 'application/json' },
      retryOn: async (attempt, error, response) => {
        if (response) {
          const transactionStatus: TransactionStatusResponse = await response.clone().json();
          return transactionStatus.transactionStatus.status === 'Unknown';
        }
        return true;
      },
      retryDelay: BLUES_CROSSCHAIN_TRANSACTION_STATUS_RETRY_DELAY_IN_SEC * 1000,
    },
  );

  const transactionStatus: TransactionStatusResponse = await response.json();
  const status = transactionStatus.transactionStatus.status;

  if (status === 'Unknown') {
    throw new TransactionStatusError(TRANSACTION_STATUS_ERROR_MESSAGES.TRANSACTION_STATUS_UNKNOWN, {
      cause: transactionStatus,
    });
  } else if (status === 'SuccessfulRollback') {
    throw new TransactionStatusError(
      TRANSACTION_STATUS_ERROR_MESSAGES.TRANSACTION_STATUS_SUCCESSFUL_ROLLBACK,
      { cause: transactionStatus },
    );
  } else if (status === 'Failed') {
    throw new TransactionStatusError(TRANSACTION_STATUS_ERROR_MESSAGES.TRANSACTION_STATUS_FAILED, {
      cause: transactionStatus,
    });
  }

  return transactionStatus;
}

export async function swapTransactionStatus(fromChainId: ChainId, txHash: string) {
  const response = await fetch(
    `${BLUES_CROSSCHAIN_URL}blues-chain/dex/transaction_statuses/${fromChainId}/${txHash}`,
    {
      method: 'get',
      headers: { 'content-type': 'application/json' },
    },
  );
  if (response.ok) {
    return Promise.resolve(response.json());
  }
  return Promise.reject(response);
}
