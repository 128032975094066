import { getRouterAddress } from '@/helpers/address.helper';
import { SLIPPAGE_TOLERANCE, TRANSACTION_DEADLINE } from '@/helpers/constants';
import {
  getErc20Contract,
  getRouterContract,
  getWethContract,
  transactionWithEstimatedGas,
} from '@/helpers/contract.helper';
import { safeDateNowPlusEstimatedMinutes, safeParseUnits } from '@/helpers/utils';
import {
  BN_ZERO,
  ethersToJSBI,
  NO_CONTRACT_ADDRESS,
  SOLIDITY_TYPE_MAXIMA,
  SolidityType,
  TradeType,
} from '@/sdk/constants';
import { TokenAmount } from '@/sdk/entities/fractions/tokenAmount';
import { Pair } from '@/sdk/entities/pair';
import { PairSourceType } from '@/sdk/entities/pairSource';
import { Token } from '@/sdk/entities/token';
import { MAX_HOPS, MIN_HOPS, Trade, TRADE_DEFAULT_OPTIONS } from '@/sdk/entities/trade';
import { WrapTrade } from '@/sdk/entities/wrapTrade';
import { basisPointsToPercent } from '@/sdk/utils';
import { MODULE_NAMES } from '@/store';
import { PORTFOLIO_ACTION_TYPES } from '@/store/modules/portfolios/portfolios.module';
import { ROUTES_ACTION_TYPES } from '@/store/modules/routes/routes.module';
import { computeTradePriceBreakdown } from '@/utils/swap.prices';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import {
  chooseBestTrade,
  getDestinationChainIdForRequest,
  isCrossChainSwap,
} from '@/helpers/tradeFilter.helper';
import { fetchSwapTransactionStatus } from '@/helpers/cross-chain-api';
import { useEVMWallet } from '@/store/modules/wallet/useEVMWallet';

export enum Fields {
  InputA = 'input',
  InputB = 'output',
}

function getInverseField(field) {
  return field === Fields.InputA ? Fields.InputB : Fields.InputA;
}

function getEstimatedField(state) {
  if (state[Fields.InputA].estimated) return Fields.InputA;
  if (state[Fields.InputB].estimated) return Fields.InputB;
  return null;
}

function checkHasTwoTokens(tokenA, tokenB) {
  return tokenA instanceof Token && tokenB instanceof Token;
}

function collectPortfoliosAddresses(pairs: Pair[] = []) {
  return pairs.map((pair: Pair) => {
    if (
      pair?.pairSource?.type === PairSourceType.PORTFOLIO ||
      pair?.pairSource?.type === PairSourceType.CROSSCHAIN_PORTFOLIO
    ) {
      return pair.pairSource.address;
    }
    return NO_CONTRACT_ADDRESS;
  });
}

function isSwapEthFroWeth(state) {
  return state[Fields.InputA].token.isETHToken() && state[Fields.InputB].token.isWETHToken();
}

function isSwapWEthFroEth(state) {
  return state[Fields.InputA].token.isWETHToken() && state[Fields.InputB].token.isETHToken();
}

export const SWAP_ACTION_TYPES = {
  SWAP_RESET_STATE: 'swapResetState',
  SWAP_SET_TOKEN: 'swapSetToken',
  SWAP_SET_TOKEN_BY_SYMBOL: 'swapSetTokenBySymbol',
  SWAP_SET_AMOUNT: 'swapSetAmount',
  SWAP_VALIDATE: 'swapValidate',
  SWAP_RESET_ERRORS: 'swapResetErrors',
  SWAP_SET_ROUTE_PAIR: 'swapSetRoutePair',
  SWAP_CHECK_ALLOWANCE: 'swapCheckAllowance',
  SWAP_SET_SETTINGS: 'swapSetSettings',
  SWAP_SEND: 'swapSend',
  SWAP_EXACT_TOKENS_FOR_TOKENS: 'swapExactTokensForTokensWithPortfolios',
  SWAP_TOKENS_FOR_EXACT_TOKENS: 'swapTokensForExactTokensWithPortfolios',
  SWAP_EXACT_ETH_FOR_TOKENS: 'swapExactETHForTokensWithPortfolios',
  SWAP_TOKENS_FOR_EXACT_ETH: 'swapTokensForExactETHWithPortfolios',
  SWAP_EXACT_TOKENS_FOR_ETH: 'swapExactTokensForETHWithPortfolios',
  SWAP_ETH_FOR_EXACT_TOKENS: 'swapETHForExactTokensWithPortfolios',
  SWAP_ETH_FOR_WETH: 'swapETHForWETH',
  SWAP_WETH_FOR_ETH: 'swapWETHForETH',
  SWAP_SWITCH_TOKENS: 'swapSwitchTokens',
  SWAP_CHECK_CROSS_CHAIN_TRANSACTION_STATUS: 'swapCheckCrossChainTransactionStatus',
};
export const SWAP_MUTATION_TYPES = {
  SET_LOADING: 'setLoading',
  SET_STATE: 'setState',
  SET_TOKEN: 'setToken',
  SET_AMOUNT: 'setAmount',
  SET_ESTIMATED: 'setEstimated',
  SET_SWAPPED_TOKENS: 'setSwappedTokens',
};

const state = {
  loading: false,
  pendingTransaction: false,
  input: {
    token: null,
    amount: null,
    estimated: false,
  },
  output: {
    token: null,
    amount: null,
    estimated: false,
  },
  settings: {
    slippageTolerance: SLIPPAGE_TOLERANCE.toString(),
    transactionDeadline: TRANSACTION_DEADLINE.toString(),
    enableMultiHops: true,
    localSwapPriority: true,
  },
  bestTrade: [],
  hasAllowance: false,
  inProgressAllowance: false,
  canSwap: false,
  switchBaseTokens: false,
  errors: {},
};

const getters = {
  getIsLoading: state => {
    return state.loading;
  },
  getSwapToken: state => field => {
    return state[field].token;
  },
  getSwapAmount: state => field => {
    return state[field].amount;
  },
  getSwapEstimated: state => field => {
    return state[field].estimated;
  },
  getAllowance: state => {
    return state.allowance;
  },
  getAllowanceInProgress: state => {
    return state.inProgressAllowance;
  },
  canSwap: state => {
    return state.canSwap;
  },
  getSwapSettings: state => {
    return state.settings;
  },
  getSwapBestTrade: state => {
    return state.bestTrade.length > 0 ? state.bestTrade[0] : null;
  },
  getSwapInfo: state => {
    if (state.bestTrade.length === 0) return null;
    const bestTrade = state.bestTrade[0];
    const pct = basisPointsToPercent(state.settings.slippageTolerance * 100);

    const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(bestTrade);
    return {
      price: bestTrade.executionPrice.toSignificant(6),
      priceInverse: bestTrade.executionPrice.invert().toSignificant(6),
      priceImpact: priceImpactWithoutFee,
      minimumReceived:
        bestTrade.tradeType === TradeType.EXACT_INPUT
          ? bestTrade.minimumAmountOut(pct).toSignificant(6)
          : null,
      maximumSolid:
        bestTrade.tradeType === TradeType.EXACT_OUTPUT
          ? bestTrade.maximumAmountIn(pct).toSignificant(6)
          : null,
      liquidityProviderFee: realizedLPFee,
      route: bestTrade.route,
    };
  },
  getErrors: state => field => {
    if (field) return state.errors[field];
    return Object.keys(state.errors).findIndex(error => state.errors[error] === true) > -1;
  },
};
const actions = {
  async [SWAP_ACTION_TYPES.SWAP_SET_SETTINGS]({ commit, dispatch }, { settings }) {
    commit(SWAP_MUTATION_TYPES.SET_STATE, { settings });
    await dispatch(SWAP_ACTION_TYPES.SWAP_SET_ROUTE_PAIR);
  },
  async [SWAP_ACTION_TYPES.SWAP_SET_TOKEN_BY_SYMBOL](
    { rootState, dispatch },
    { field, tokenSymbol }: { field: Fields; tokenSymbol: string },
  ) {
    // TODO: tokens.baseToken moved to useTokens -> getGasToken
    const availableTokens = {
      [rootState.tokens.baseToken.name]: rootState.tokens.baseToken,
      ...rootState.tokens.tokens,
    };
    const token: Token = availableTokens[tokenSymbol];
    if (token) {
      await dispatch(SWAP_ACTION_TYPES.SWAP_SET_TOKEN, { field, token });
    }
  },
  async [SWAP_ACTION_TYPES.SWAP_SET_TOKEN]({ commit, dispatch }, { field, token }) {
    commit(SWAP_MUTATION_TYPES.SET_TOKEN, { field, token });
    dispatch(SWAP_ACTION_TYPES.SWAP_RESET_ERRORS);
    await dispatch(SWAP_ACTION_TYPES.SWAP_SET_ROUTE_PAIR);
  },
  async [SWAP_ACTION_TYPES.SWAP_SWITCH_TOKENS]({ commit, dispatch }) {
    commit(SWAP_MUTATION_TYPES.SET_SWAPPED_TOKENS);
    dispatch(SWAP_ACTION_TYPES.SWAP_RESET_ERRORS);
    await dispatch(SWAP_ACTION_TYPES.SWAP_SET_ROUTE_PAIR);
  },
  async [SWAP_ACTION_TYPES.SWAP_SET_AMOUNT]({ commit, dispatch }, { field, amount }) {
    commit(SWAP_MUTATION_TYPES.SET_AMOUNT, { field, amount });
    commit(SWAP_MUTATION_TYPES.SET_ESTIMATED, { field: getInverseField(field), estimated: true });
    dispatch(SWAP_ACTION_TYPES.SWAP_RESET_ERRORS);
    await dispatch(SWAP_ACTION_TYPES.SWAP_SET_ROUTE_PAIR);
  },
  [SWAP_ACTION_TYPES.SWAP_RESET_ERRORS]({ commit }) {
    const errors = {
      [Fields.InputA]: false,
      [Fields.InputB]: false,
      form: false,
      sameTokens: false,
    };
    commit(SWAP_MUTATION_TYPES.SET_STATE, { errors });
  },
  async [SWAP_ACTION_TYPES.SWAP_VALIDATE]({ state, rootState, commit }) {
    let errors = {
      [Fields.InputA]: false,
      [Fields.InputB]: false,
      form: false,
      sameTokens: false,
    };
    commit(SWAP_MUTATION_TYPES.SET_STATE, { errors });

    if (!state[Fields.InputA].token) {
      return;
    }

    const tokenABalance = rootState.tokens.balances[state[Fields.InputA].token.symbol]?.balance;

    const tokenAAmount = new TokenAmount(
      state[Fields.InputA].token,
      safeParseUnits(state[Fields.InputA].amount, state[Fields.InputA].token.decimals),
    );

    let hasFormError = !state.bestTrade[0];

    if (
      !state[Fields.InputA]?.token ||
      !state[Fields.InputB]?.token ||
      !(state[Fields.InputA].amount || state[Fields.InputB].amount)
    ) {
      hasFormError = false;
    }

    errors = {
      [Fields.InputA]: tokenAAmount.greaterThan(tokenABalance ?? 0),
      [Fields.InputB]: false,
      form: hasFormError,
      sameTokens:
        state[Fields.InputB]?.token &&
        state[Fields.InputA]?.token &&
        state[Fields.InputA].token.isSameSymbol(state[Fields.InputB].token),
    };
    commit(SWAP_MUTATION_TYPES.SET_STATE, { errors });
  },
  async [SWAP_ACTION_TYPES.SWAP_SET_ROUTE_PAIR](
    { state, dispatch, commit },
    noRecursiveReRun = false,
  ) {
    if (!checkHasTwoTokens(state[Fields.InputA].token, state[Fields.InputB].token)) {
      return;
    }

    const notEstimatedFieldType = getInverseField(getEstimatedField(state) ?? Fields.InputB);
    const notEstimatedField = state[notEstimatedFieldType];

    if (state[Fields.InputA].token.isSameSymbol(state[Fields.InputB].token)) {
      commit(SWAP_MUTATION_TYPES.SET_AMOUNT, {
        field: getEstimatedField(state),
        amount: notEstimatedField.amount,
      });
      dispatch(SWAP_ACTION_TYPES.SWAP_VALIDATE);
      return;
    }

    if (!getEstimatedField(state)) {
      commit(SWAP_MUTATION_TYPES.SET_ESTIMATED, { field: Fields.InputB, estimated: true });
    }
    dispatch(SWAP_ACTION_TYPES.SWAP_CHECK_ALLOWANCE);

    const tokenAmount = new TokenAmount(
      notEstimatedField.token,
      safeParseUnits(notEstimatedField.amount, notEstimatedField.token.decimals),
    );
    if (tokenAmount.equalTo(BN_ZERO)) {
      commit(SWAP_MUTATION_TYPES.SET_AMOUNT, { field: getEstimatedField(state), amount: '' });
      commit(SWAP_MUTATION_TYPES.SET_STATE, { bestTrade: [] });
      dispatch(SWAP_ACTION_TYPES.SWAP_VALIDATE);
      return;
    }

    const tradeType =
      notEstimatedFieldType === Fields.InputA ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT;

    if (isSwapEthFroWeth(state) || isSwapWEthFroEth(state)) {
      commit(SWAP_MUTATION_TYPES.SET_AMOUNT, {
        field: getEstimatedField(state),
        amount: tokenAmount.toExact(),
      });
      commit(SWAP_MUTATION_TYPES.SET_STATE, {
        bestTrade: [
          new WrapTrade(
            tradeType,
            state[Fields.InputA].token,
            state[Fields.InputB].token,
            tokenAmount,
          ),
        ],
      });
      dispatch(SWAP_ACTION_TYPES.SWAP_VALIDATE);
      return;
    }

    commit(SWAP_MUTATION_TYPES.SET_LOADING, true);

    const tradeOptions = Object.assign(TRADE_DEFAULT_OPTIONS, {
      maxHops: state.settings.enableMultiHops ? MAX_HOPS : MIN_HOPS,
      isCrossChainTrade: state[Fields.InputA].token.chainId !== state[Fields.InputB].token.chainId,
      enabledLocalPriority: state.settings.localSwapPriority,
    });

    let trades: Trade[] = [];
    if (notEstimatedFieldType === Fields.InputA) {
      trades = await dispatch(
        MODULE_NAMES.ROUTES + '/' + ROUTES_ACTION_TYPES.CALCULATE_ROUTES_FROM_IN,
        {
          currencyAmountA: tokenAmount,
          tokenB: state[getInverseField(notEstimatedFieldType)].token,
          tradeOptions,
        },
        { root: true },
      );
      trades = chooseBestTrade(
        trades,
        state.settings.localSwapPriority,
        state[Fields.InputA].token,
        state[Fields.InputB].token,
      );

      commit(SWAP_MUTATION_TYPES.SET_STATE, { bestTrade: trades });
      commit(SWAP_MUTATION_TYPES.SET_AMOUNT, {
        field: getInverseField(notEstimatedFieldType),
        amount: trades[0]?.outputAmount.toExact() || '',
      });
    }
    if (notEstimatedFieldType === Fields.InputB) {
      trades = await dispatch(
        MODULE_NAMES.ROUTES + '/' + ROUTES_ACTION_TYPES.CALCULATE_ROUTES_FROM_OUT,
        {
          tokenA: state[getInverseField(notEstimatedFieldType)].token,
          currencyAmountB: tokenAmount,
          tradeOptions,
        },
        { root: true },
      );

      trades = chooseBestTrade(
        trades,
        state.settings.localSwapPriority,
        state[Fields.InputA].token,
        state[Fields.InputB].token,
      );

      commit(SWAP_MUTATION_TYPES.SET_STATE, { bestTrade: trades });
      commit(SWAP_MUTATION_TYPES.SET_AMOUNT, {
        field: getInverseField(notEstimatedFieldType),
        amount: trades[0]?.inputAmount.toExact() || '',
      });
    }

    if (!trades.length && !noRecursiveReRun) {
      await dispatch(MODULE_NAMES.PORTFOLIOS + '/' + PORTFOLIO_ACTION_TYPES.INIT_PORTFOLIOS, null, {
        root: true,
      });
      await dispatch(SWAP_ACTION_TYPES.SWAP_SET_ROUTE_PAIR, true);
      commit(SWAP_MUTATION_TYPES.SET_LOADING, false);
      return;
    }

    commit(SWAP_MUTATION_TYPES.SET_LOADING, false);
    dispatch(SWAP_ACTION_TYPES.SWAP_VALIDATE);
  },
  async [SWAP_ACTION_TYPES.SWAP_CHECK_ALLOWANCE]({ state, commit }, sendApprove) {
    commit(SWAP_MUTATION_TYPES.SET_STATE, { allowance: false, inProgressAllowance: true });

    const inputToken = state[Fields.InputA].token;

    if (inputToken.isETHToken() || isSwapWEthFroEth(state)) {
      commit(SWAP_MUTATION_TYPES.SET_STATE, { allowance: true, inProgressAllowance: false });
      return;
    }
    const tokenContract = getErc20Contract(inputToken.address, getInstance()?.web3?.getSigner());
    const { walletState } = useEVMWallet();
    const allowance = await tokenContract.allowance(walletState.account, getRouterAddress());
    const tokenAllowanceAmount = new TokenAmount(inputToken, ethersToJSBI(allowance));
    const tokenInputAmount = new TokenAmount(
      inputToken,
      safeParseUnits(state[Fields.InputA].amount, inputToken.decimals),
    );
    const amountGteAllowance = tokenInputAmount.greaterThan(tokenAllowanceAmount);
    console.groupCollapsed(
      `Need approve ${inputToken.symbol} | ${inputToken.decimals} : ${amountGteAllowance}`,
    );
    console.log(`${inputToken.symbol}     input: `, tokenInputAmount.raw.toString());
    console.log(`${inputToken.symbol} allowance: `, tokenAllowanceAmount.raw.toString());
    console.groupEnd();
    if (amountGteAllowance && sendApprove === true) {
      const result = await transactionWithEstimatedGas(tokenContract, 'approve', [
        getRouterAddress(),
        SOLIDITY_TYPE_MAXIMA[SolidityType.uint256].toString(),
      ]);

      await result.wait();
    } else if (!amountGteAllowance) {
      commit(SWAP_MUTATION_TYPES.SET_STATE, { allowance: true });
    }
    commit(SWAP_MUTATION_TYPES.SET_STATE, { inProgressAllowance: false });
  },
  async [SWAP_ACTION_TYPES.SWAP_SEND]({ state, dispatch }) {
    const bestTrade = state.bestTrade[0];

    if (isSwapEthFroWeth(state)) {
      return await dispatch(SWAP_ACTION_TYPES.SWAP_ETH_FOR_WETH);
    }

    if (isSwapWEthFroEth(state)) {
      return await dispatch(SWAP_ACTION_TYPES.SWAP_WETH_FOR_ETH);
    }

    if (bestTrade.tradeType === TradeType.EXACT_INPUT || isCrossChainSwap(bestTrade)) {
      console.log('bestTrade.tradeType TradeType.EXACT_INPUT');
      if (state.input.token.isETHToken()) {
        return await dispatch(SWAP_ACTION_TYPES.SWAP_EXACT_ETH_FOR_TOKENS);
      } else if (state.output.token.isETHToken()) {
        return await dispatch(SWAP_ACTION_TYPES.SWAP_EXACT_TOKENS_FOR_ETH);
      } else {
        return await dispatch(SWAP_ACTION_TYPES.SWAP_EXACT_TOKENS_FOR_TOKENS);
      }
    } else if (bestTrade.tradeType === TradeType.EXACT_OUTPUT) {
      console.log('bestTrade.tradeType TradeType.EXACT_OUTPUT');
      if (state.input.token.isETHToken()) {
        return await dispatch(SWAP_ACTION_TYPES.SWAP_ETH_FOR_EXACT_TOKENS);
      } else if (state.output.token.isETHToken()) {
        return await dispatch(SWAP_ACTION_TYPES.SWAP_TOKENS_FOR_EXACT_ETH);
      } else {
        return await dispatch(SWAP_ACTION_TYPES.SWAP_TOKENS_FOR_EXACT_TOKENS);
      }
    }
  },
  async [SWAP_ACTION_TYPES.SWAP_EXACT_TOKENS_FOR_TOKENS]({ state }) {
    console.log('SWAP_EXACT_TOKENS_FOR_TOKENS');
    const bestTrade = state.bestTrade[0];
    const bestRoutesForTradeTokenAddresses = bestTrade.route.path.map(r => r.address);
    const bestRoutesForTradePortfolioAddresses = collectPortfoliosAddresses(bestTrade.route.pairs);
    const inputAmount = bestTrade.inputAmount.raw.toString();
    const pct = basisPointsToPercent(state.settings.slippageTolerance * 100);
    const minAmountOut = bestTrade.minimumAmountOut(pct).raw.toString();
    const { walletState } = useEVMWallet();

    console.log('SWAP_EXACT_TOKENS_FOR_TOKENS:swapExactTokensForTokensWithPortfolios:params: ', {
      amountIn: inputAmount,
      amountOutMin: minAmountOut,
      path: bestRoutesForTradeTokenAddresses,
      to: walletState.account,
      portfolios: bestRoutesForTradePortfolioAddresses,
      destinationChain: getDestinationChainIdForRequest(bestTrade),
    });

    const routerContract = getRouterContract(getRouterAddress(), getInstance().web3.getSigner());

    const result = await transactionWithEstimatedGas(
      routerContract,
      'swapExactTokensForTokensWithPortfolios',
      [
        inputAmount,
        minAmountOut,
        bestRoutesForTradeTokenAddresses,
        walletState.account,
        safeDateNowPlusEstimatedMinutes(state.settings.transactionDeadline),
        bestRoutesForTradePortfolioAddresses,
        getDestinationChainIdForRequest(bestTrade),
      ],
    );

    return await result.wait().then(function (result) {
      return result;
    });
  },
  async [SWAP_ACTION_TYPES.SWAP_TOKENS_FOR_EXACT_TOKENS]({ state }) {
    console.log('SWAP_TOKENS_FOR_EXACT_TOKENS');
    const bestTrade = state.bestTrade[0];
    const bestRoutesForTradeTokenAddresses = bestTrade.route.path.map(r => r.address);
    const bestRoutesForTradePortfolioAddresses = collectPortfoliosAddresses(bestTrade.route.pairs);
    const amountOut = bestTrade.outputAmount.raw.toString();
    const pct = basisPointsToPercent(state.settings.slippageTolerance * 100);
    const amountInMax = bestTrade.maximumAmountIn(pct).raw.toString();
    const destinationChainId = 0;
    const { walletState } = useEVMWallet();

    console.log('SWAP_TOKENS_FOR_EXACT_TOKENS:swapTokensForExactTokensWithPortfolios:params: ', {
      amountOut: amountOut,
      amountInMax: amountInMax,
      path: bestRoutesForTradeTokenAddresses,
      to: walletState.account,
      portfolios: bestRoutesForTradePortfolioAddresses,
    });

    const routerContract = getRouterContract(getRouterAddress(), getInstance().web3.getSigner());

    const result = await transactionWithEstimatedGas(
      routerContract,
      'swapTokensForExactTokensWithPortfolios',
      [
        amountOut,
        amountInMax,
        bestRoutesForTradeTokenAddresses,
        walletState.account,
        safeDateNowPlusEstimatedMinutes(state.settings.transactionDeadline),
        bestRoutesForTradePortfolioAddresses,
        destinationChainId,
      ],
    );

    return await result.wait().then(function (result) {
      return result;
    });
  },
  async [SWAP_ACTION_TYPES.SWAP_EXACT_ETH_FOR_TOKENS]({ state }) {
    console.log('SWAP_ACTION_TYPES.SWAP_EXACT_ETH_FOR_TOKENS');
    const bestTrade = state.bestTrade[0];
    const bestRoutesForTradeTokenAddresses = bestTrade.route.path.map(r => r.address);
    const bestRoutesForTradePortfolioAddresses = collectPortfoliosAddresses(bestTrade.route.pairs);
    const inputAmount = bestTrade.inputAmount.raw.toString();
    const pct = basisPointsToPercent(state.settings.slippageTolerance * 100);
    const minAmountOut = bestTrade.minimumAmountOut(pct).raw.toString();
    const { walletState } = useEVMWallet();
    const overrides = {
      value: inputAmount,
    };

    console.log('SWAP_EXACT_ETH_FOR_TOKENS:swapExactETHForTokensWithPortfolios:params: ', {
      amountOutMin: minAmountOut,
      path: bestRoutesForTradeTokenAddresses,
      to: walletState.account,
      portfolios: bestRoutesForTradePortfolioAddresses,
      overrides: overrides,
      destinationChain: getDestinationChainIdForRequest(bestTrade),
    });

    const routerContract = getRouterContract(getRouterAddress(), getInstance().web3.getSigner());

    const result = await transactionWithEstimatedGas(
      routerContract,
      'swapExactETHForTokensWithPortfolios',
      [
        minAmountOut,
        bestRoutesForTradeTokenAddresses,
        walletState.account,
        safeDateNowPlusEstimatedMinutes(state.settings.transactionDeadline),
        bestRoutesForTradePortfolioAddresses,
        getDestinationChainIdForRequest(bestTrade),
      ],
      overrides,
    );

    return await result.wait().then(function (result) {
      return result;
    });
  },
  async [SWAP_ACTION_TYPES.SWAP_EXACT_TOKENS_FOR_ETH]({ state }) {
    console.log('SWAP_ACTION_TYPES.SWAP_EXACT_TOKENS_FOR_ETH');
    const bestTrade = state.bestTrade[0];
    const bestRoutesForTradeTokenAddresses = bestTrade.route.path.map(r => r.address);
    const bestRoutesForTradePortfolioAddresses = collectPortfoliosAddresses(bestTrade.route.pairs);
    const inputAmount = bestTrade.inputAmount.raw.toString();
    const pct = basisPointsToPercent(state.settings.slippageTolerance * 100);
    const minAmountOut = bestTrade.minimumAmountOut(pct).raw.toString();
    const { walletState } = useEVMWallet();

    console.log('SWAP_EXACT_TOKENS_FOR_ETH:swapExactTokensForETHWithPortfolios:params: ', {
      amountIn: inputAmount,
      amountOutMin: minAmountOut,
      path: bestRoutesForTradeTokenAddresses,
      to: walletState.account,
      portfolios: bestRoutesForTradePortfolioAddresses,
      destinationChain: getDestinationChainIdForRequest(bestTrade),
    });

    const routerContract = getRouterContract(getRouterAddress(), getInstance().web3.getSigner());

    const result = await transactionWithEstimatedGas(
      routerContract,
      'swapExactTokensForETHWithPortfolios',
      [
        inputAmount,
        minAmountOut,
        bestRoutesForTradeTokenAddresses,
        walletState.account,
        safeDateNowPlusEstimatedMinutes(state.settings.transactionDeadline),
        bestRoutesForTradePortfolioAddresses,
        getDestinationChainIdForRequest(bestTrade),
      ],
    );

    return await result.wait().then(function (result) {
      return result;
    });
  },
  async [SWAP_ACTION_TYPES.SWAP_ETH_FOR_EXACT_TOKENS]({ state }) {
    console.log('SWAP_ACTION_TYPES.SWAP_ETH_FOR_EXACT_TOKENS');
    const bestTrade = state.bestTrade[0];
    const bestRoutesForTradeTokenAddresses = bestTrade.route.path.map(r => r.address);
    const bestRoutesForTradePortfolioAddresses = collectPortfoliosAddresses(bestTrade.route.pairs);
    const amountOut = bestTrade.outputAmount.raw.toString();
    const pct = basisPointsToPercent(state.settings.slippageTolerance * 100);
    const inputAmount = bestTrade.maximumAmountIn(pct).raw.toString();
    const destinationChainId = 0;
    const { walletState } = useEVMWallet();
    const overrides = {
      value: inputAmount,
    };

    console.log('SWAP_ETH_FOR_EXACT_TOKENS:swapETHForExactTokensWithPortfolios:params: ', {
      amountOut: amountOut,
      path: bestRoutesForTradeTokenAddresses,
      to: walletState.account,
      portfolios: bestRoutesForTradePortfolioAddresses,
      overrides: overrides,
    });

    const routerContract = getRouterContract(getRouterAddress(), getInstance().web3.getSigner());

    const result = await transactionWithEstimatedGas(
      routerContract,
      'swapETHForExactTokensWithPortfolios',
      [
        amountOut,
        bestRoutesForTradeTokenAddresses,
        walletState.account,
        safeDateNowPlusEstimatedMinutes(state.settings.transactionDeadline),
        bestRoutesForTradePortfolioAddresses,
        destinationChainId,
      ],
      overrides,
    );

    return await result.wait().then(function (result) {
      return result;
    });
  },
  async [SWAP_ACTION_TYPES.SWAP_TOKENS_FOR_EXACT_ETH]({ state }) {
    console.log('SWAP_ACTION_TYPES.SWAP_TOKENS_FOR_EXACT_ETH');
    const bestTrade = state.bestTrade[0];
    const bestRoutesForTradeTokenAddresses = bestTrade.route.path.map(r => r.address);
    const bestRoutesForTradePortfolioAddresses = collectPortfoliosAddresses(bestTrade.route.pairs);
    const amountOut = bestTrade.outputAmount.raw.toString();
    const pct = basisPointsToPercent(state.settings.slippageTolerance * 100);
    const inputAmount = bestTrade.maximumAmountIn(pct).raw.toString();
    const destinationChainId = 0;
    const { walletState } = useEVMWallet();

    console.log('SWAP_TOKENS_FOR_EXACT_ETH:swapTokensForExactETHWithPortfolios:params: ', {
      amountOut: amountOut,
      amountInMax: inputAmount,
      path: bestRoutesForTradeTokenAddresses,
      to: walletState.account,
      portfolios: bestRoutesForTradePortfolioAddresses,
    });

    const routerContract = getRouterContract(getRouterAddress(), getInstance().web3.getSigner());

    const result = await transactionWithEstimatedGas(
      routerContract,
      'swapTokensForExactETHWithPortfolios',
      [
        amountOut,
        inputAmount,
        bestRoutesForTradeTokenAddresses,
        walletState.account,
        safeDateNowPlusEstimatedMinutes(state.settings.transactionDeadline),
        bestRoutesForTradePortfolioAddresses,
        destinationChainId,
      ],
    );

    return await result.wait().then(function (result) {
      return result;
    });
  },
  async [SWAP_ACTION_TYPES.SWAP_ETH_FOR_WETH]({ state }) {
    const fromToken: Token = state[Fields.InputA].token;
    const toToken: Token = state[Fields.InputB].token;
    const trade: Trade = state.bestTrade[0];

    if (!trade.inputAmount.equalTo(trade.outputAmount)) {
      throw new Error(`Eth-weth input must be eq to output`);
    }

    if (!fromToken.isETHToken() || !toToken.isWETHToken()) {
      throw new Error(`Called swap eth for weth for not eth-weth inputs`);
    }

    const wethContract = getWethContract(toToken.address, getInstance().web3.getSigner());

    const overrides = { value: trade.inputAmount.raw.toString() };

    const transaction = await transactionWithEstimatedGas(wethContract, 'deposit', [], overrides);
    await transaction.wait();
  },
  async [SWAP_ACTION_TYPES.SWAP_WETH_FOR_ETH]({ state }) {
    const fromToken: Token = state[Fields.InputA].token;
    const toToken: Token = state[Fields.InputB].token;
    const trade: Trade = state.bestTrade[0];

    if (!trade.inputAmount.equalTo(trade.outputAmount)) {
      throw new Error(`Weth-eth input must be eq to output`);
    }

    if (!fromToken.isWETHToken() || !toToken.isETHToken()) {
      throw new Error(`Called swap weth for eth for not weth-eth inputs`);
    }

    const wethContract = getWethContract(fromToken.address, getInstance().web3.getSigner());

    const transaction = await transactionWithEstimatedGas(wethContract, 'withdraw', [
      trade.inputAmount.raw.toString(),
    ]);

    await transaction.wait();
  },
  async [SWAP_ACTION_TYPES.SWAP_CHECK_CROSS_CHAIN_TRANSACTION_STATUS](_, { fromChainId, txHash }) {
    return await fetchSwapTransactionStatus(fromChainId, txHash);
  },
};
const mutations = {
  [SWAP_MUTATION_TYPES.SET_STATE](_state, payload) {
    Object.keys(payload).forEach(key => {
      _state[key] = payload[key];
    });
  },
  async [SWAP_MUTATION_TYPES.SET_TOKEN](_state, { field, token }) {
    _state[field].token = token;
  },
  [SWAP_MUTATION_TYPES.SET_LOADING](_state, isLoading: boolean) {
    _state.loading = isLoading;
  },
  [SWAP_MUTATION_TYPES.SET_SWAPPED_TOKENS](_state) {
    [
      _state[Fields.InputA].token,
      _state[Fields.InputB].token,
      _state[Fields.InputA].amount,
      _state[Fields.InputB].amount,
      _state[Fields.InputA].estimated,
      _state[Fields.InputB].estimated,
    ] = [
      _state[Fields.InputB].token,
      _state[Fields.InputA].token,
      _state[Fields.InputB].amount,
      _state[Fields.InputA].amount,
      _state[Fields.InputB].estimated,
      _state[Fields.InputA].estimated,
    ];
  },
  async [SWAP_MUTATION_TYPES.SET_AMOUNT](_state, { field, amount }) {
    _state[field].amount = amount;
  },
  async [SWAP_MUTATION_TYPES.SET_ESTIMATED](_state, payload) {
    _state[payload.field].estimated = payload.estimated;
    _state[getInverseField(payload.field)].estimated = false;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
