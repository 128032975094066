import { NETWORK_BACKEND_URL } from '@/helpers/networkParams.helper';

type PriceResponse = {
  price: string[];
};

export async function getPriceInUSD(symbol: string): Promise<string> {
  const response = await fetch(`${NETWORK_BACKEND_URL}price/token-symbol/vs/usd`, {
    method: 'post',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      token: [symbol],
      isHumanReadable: false,
    }),
  });
  const data: PriceResponse = await response.json();

  return data.price[0];
}

export async function fetchPricesInUSDByAddresses(tokens: string[]): Promise<string[]> {
  const pricesRequest = await fetch(`${NETWORK_BACKEND_URL + 'price/token-id/vs/usd'}`, {
    method: 'post',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      token: tokens,
      isHumanReadable: false,
    }),
  });

  const pricesJson: PriceResponse = await pricesRequest.json();
  return pricesJson.price;
}

export async function fetchPricesInBaseToken(
  portfolioBaseTokens: string[],
  baseTokenAddress: string,
): Promise<string[]> {
  const pricesRequest = await fetch(`${NETWORK_BACKEND_URL + 'pool/getPrices'}`, {
    method: 'post',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      signature: false,
      tokens: portfolioBaseTokens,
      baseToken: baseTokenAddress,
    }),
  });

  const pricesJson: { price: string[] } = await pricesRequest.json();
  return pricesJson.price;
}
