import { DEFAULT_CHAIN_ID } from '@/constants/DEFAULT_CHAIN_ID';
import addresses from '@/data/contracts/contracts';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';

export const getAddress = (address: any): string => {
  const chainId = DEFAULT_NETWORK_ID ?? DEFAULT_CHAIN_ID;
  return address[chainId] ? address[chainId] : address[DEFAULT_CHAIN_ID];
};

export const getPoolFactoryAddress = () => {
  return getAddress(addresses.poolFactory);
};

export const getRouterAddress = () => {
  return getAddress(addresses.router);
};

export const getMinterAddress = () => {
  return getAddress(addresses.minter);
};

export const getPortfolioAndPairRegistryAddress = () => {
  return getAddress(addresses.portfolioAndPairRegistry);
};

export const getMultiCallAddress = () => {
  return getAddress(addresses.multiCall);
};

export const getAutoPoolAddress = () => {
  return getAddress(addresses.autoPool);
};

export const getLiquidityDelegateAddress = () => {
  return getAddress(addresses.liquidityDelegate);
};
