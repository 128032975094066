import liquifiTokens from '@/data/lists/liquifi.tokenlist.json';
import { BASE_TOKENS } from '@/constants/baseTokens';
import { NATIVE_TOKEN_SYMBOL } from '@/helpers/constants';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';

export const NETWORK_ETH_TOKEN_NAME = ENABLE_FAKE_CARDANO_NETWORK
  ? 'ADA'
  : BASE_TOKENS[String(DEFAULT_NETWORK_ID)].symbol;

export const NETWORK_ETH_TOKEN_ADDRESS = BASE_TOKENS[String(DEFAULT_NETWORK_ID)].address;

export const NETWORK_WETH_TOKEN_NAME = liquifiTokens.tokens
  .filter(token => token.address == NETWORK_ETH_TOKEN_ADDRESS)
  .map(token => token.symbol)[0];

export const CURRENT_NATIVE_TOKEN_ADDRESS = liquifiTokens.tokens
  .filter(
    token => token.symbol == NATIVE_TOKEN_SYMBOL && String(token.chainId) == DEFAULT_NETWORK_ID,
  )
  .map(token => token.address)[0];
